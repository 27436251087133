<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">调拨单基本信息</el-col>
    </el-row>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="调拨类型" prop="transferTypeDict">
            <el-select v-model="form.transferTypeDict" disabled>
              <el-option :value="'1'" label="仓内调拨" />
              <el-option :value="'2'" label="海外调拨" />
              <el-option :value="'3'" label="国内补货" />
              <el-option :value="'4'" label="国内调拨" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="调出仓库" prop="outWarehouseCode">
            <el-select
              v-model="form.outWarehouseCode"
              clearable
              filterable
              :disabled="isNull"
              @change="getWarehouse"
            >
              <el-option
                v-for="item in warehouseOption"
                :key="item.logicWarehouseCode"
                :label="item.warehouseName"
                :value="item.logicWarehouseCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="调入仓库" prop="inWarehouseCode">
            <el-select
              v-model="form.inWarehouseCode"
              clearable
              filterable
              :disabled="isNull"
              @change="getWarehouse"
            >
              <el-option
                v-for="item in warehouseOption"
                :key="item.logicWarehouseCode"
                :label="item.warehouseName"
                :value="item.logicWarehouseCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="期望上架日期" :class="$i18n.locale" :rules="status === 0 ? rules.expectShelfDate : [{require: false}]" prop="expectShelfDate">
            <el-date-picker
              v-model="form.expectShelfDate"
              value-format="yyyy-MM-dd"
              type="date"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="调出备货渠道" prop="outStockChannelId">
            <Select
              v-model="form.outStockChannelId"
              :disabled="isNull"
              :select-options="StockChannelOptions1"
              :configuration="{ key: 'channelId', label: 'channelName', value: 'channelId' }"
              clearable
              filterable
              @change="getOutStock"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="调入备货渠道" prop="inStockChannelId" :rules="status !== 0 ? rules.inStockChannelId : rules.in">
            <Select
              v-model="form.inStockChannelId"
              :select-options="StockChannelOptions2"
              :configuration="{ key: 'channelId', label: 'channelName', value: 'channelId' }"
              clearable
              filterable
              :disabled="status === 0 || isNull"
              @change="getOutStock"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="form.remark" type="textarea" :maxlength="256" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">调拨明细</span>
        <el-button type="primary" class="ml-2" :loading="addLoading" :disabled="!isNull && isInsert" @click="Addall()">批量新增</el-button>
        <el-button v-if="isNull" type="danger" @click="handleDelete()">删除</el-button>
      </el-col>
    </el-row>
    <vxe-table
      ref="uploadTableDataRef"
      border
      :data="outboundDetailTable"
      max-height="500px"
      :edit-config="{trigger: 'click', mode: 'cell'}"
      :edit-rules="{...detailRules,
                    platSku: [{ required: !isInWare, message: '必填' }],
                    fnskuUpc: [{ required: !isInWare, message: '必填' }],
      }"
      @checkbox-change="({records})=>{selectlist=records}"
      @checkbox-all="({records})=>{selectlist=records}"
    >
      <vxe-column type="checkbox" width="60" />
      <vxe-column field="categoryName1" title="一级类别" />
      <vxe-column field="specificationModel" title="规格型号" />
      <vxe-column field="styleName" title="Style" />
      <vxe-column field="color" title="Color" />
      <vxe-column field="size" title="Size" />
      <vxe-column field="sku" title="Sku" />
      <vxe-column field="platSku" title="Platsku" prop="platSku" :edit-render="{}">
        <template #edit="{row}">
          <vxe-select v-model="row.platSku" transfer @change="e=>getfuValue(e.value, row)">
            <vxe-option v-for="(item, index) in row.platSkuDTOList" :key="index&&item.platSku" :value="item" :label="item.platSku" />
          </vxe-select>
        </template>
        <template #default="{row}">
          <vxe-select v-model="row.platSku" transfer @change="e=>getfuValue(e.value, row)">
            <vxe-option v-for="(item, index) in row.platSkuDTOList" :key="index&&item.platSku" :value="item" :label="item.platSku" />
          </vxe-select>

        </template>
      </vxe-column>
      <vxe-column field="fnskuUpc" title="Fnsku/UPC" prop="fnskuUpc" disabled />
      <vxe-column field="position" title="定位" />
      <vxe-column field="negativeCashFlag" title="是否负现金流" />
      <vxe-column field="availableStock" title="可用库存" />
      <vxe-column field="expectTransferNumber" title="预计调拨数量" prop="expectTransferNumber" :edit-render="{}">
        <template #header="{ column }">
          <span>
            <span>{{ column.title }}</span>
            <br>
            <el-button type="text" :disabled="!isNull" @click="getValue">一键调拨</el-button>
          </span>
        </template>
        <template #edit="{ row }">
          <vxe-input v-model="row.expectTransferNumber" type="integer" :min="row.availableStock===0 ? 0: 1" :max="row.availableStock" placeholder="输入数值" />
        </template>
        <template #default="{ row }">
          <span>{{ row.expectTransferNumber }}</span>
        </template>
      </vxe-column>
    </vxe-table>

    <el-row class="grid-content bg-blue" type="flex" justify="center" style="margin-top: 20px;">
      <el-button type="primary" @click="goBack">返回</el-button>
      <el-button :loading="Loadingsave" type="primary" @click="onSave">保存</el-button>
      <el-button :loading="Loadingcommit" type="primary" @click="onSubmit">提交</el-button>
    </el-row>
  </div>
</template>
<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Select from '@/components/Selection'
import { getPhysicsWarehouseList, StockChannelList, calcTransferType, batchInsertPreValidate, getmodifyTransferView, modifyTransferSave, modifyTransferSubmit } from '@/api/scm-api'

export default {
  components: { Select },
  mixins: [commodityInfoDict],
  data() {
    var validateInStock = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('必填'))
      } else if (value && this.errorInStock) {
        callback(new Error('请重新选择'))
      } else {
        callback()
      }
    }
    var validateOutStock = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('必填'))
      } else if (value && this.errorOutStock) {
        callback(new Error('请重新选择'))
      } else {
        callback()
      }
    }
    var validateInWare = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('必填'))
      } else if (value && this.errorInWare) {
        callback(new Error('请重新选择'))
      } else {
        callback()
      }
    }
    var validateOutWare = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('必填'))
      } else if (value && this.errorOutWare) {
        callback(new Error('请重新选择'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      editVisible: false,
      dialogVisible: false,
      fordisabled: false, // 禁用文本框
      isDisabled: false,
      importdata: [],
      importdialog: false,
      importDetaildialog: false,
      file: '',
      fileList: [],
      importDialog: false,
      uploadTableVisible: false,
      editForm: {
        effectiveStartDate: '',
        effectiveEndDate: '',
        isExcludingTax: 1
      },
      sidvoList: [],
      ids: [],
      vendorOptions: [],
      employeeOptions: [],
      styleOptions: [],
      editRow: {},
      showRow: false, // 切换显示input
      releaseTaskDialog: false, // 释放任务
      Loadingsave: false,
      Loadingcommit: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      row: '',
      editFormRules: {
        offerPrice: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }]
      },
      tabClickIndex: '',
      tabClickLabel: '',
      vendorId: '',
      warehouseOption: [],
      StockChannelOptions: [],
      StockChannelOptions1: [],
      StockChannelOptions2: [],
      form: {
        transferTypeDict: '',
        expectShelfDate: '',
        outWarehouseCode: '',
        inWarehouseCode: '',
        outStockChannelId: '',
        inStockChannelId: ''
      },
      rules: {
        transferTypeDict: [{ required: true, message: '必填' }],
        expectShelfDate: [{ required: true, message: '请选择', trigger: 'change' }],
        inStockChannelId: [{ required: true, message: '必填' }, { validator: validateInStock, trigger: 'blur' }],
        outStockChannelId: [{ required: true, message: '必填' }, { validator: validateOutStock, trigger: 'blur' }],
        inWarehouseCode: [{ required: true, message: '必填' }, { validator: validateInWare, trigger: 'blur' }],
        outWarehouseCode: [{ required: true, message: '必填' }, { validator: validateOutWare, trigger: 'blur' }],
        in: [{ require: false }, { validator: validateInStock, trigger: 'blur' }]
      },
      errorInWare: false,
      errorOutWare: false,
      errorInStock: false,
      errorOutStock: false,
      status: 0,
      outboundDetailTable: [],
      detailRules: {
        expectTransferNumber: [{ required: true, message: '必填' }]
      },
      FnskuUpcDisabled: false,
      addLoading: false
    }
  },
  computed: {
    isIframe() {
      return this.$route.path.includes('/iframe/')
    },
    isGap() {
      return this.status === 1 || ['1', '4'].includes(this.form.transferTypeDict)
    },
    isInWare() {
      return this.status === 1 || this.form.transferTypeDict === '1'
    },
    isInsert() {
      if (this.form.transferTypeDict && this.form.outWarehouseCode && this.form.outStockChannelId && this.form.inWarehouseCode && this.form.inStockChannelId) {
        if (this.status === 1 || (this.status !== 1 && this.form.expectShelfDate)) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    isNull() {
      if (Array.isArray(this.outboundDetailTable) && this.outboundDetailTable.length > 0) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    'editVisible'(val) {
      if (!val) {
        this.editForm = this.$options.data.call(this).editForm
        this.$refs['editForm'].resetFields()
      }
    }

  },
  mounted() {
    this._queryWarehouseList()
    this._StockChannelList()
    if (this.$route.query.isedit) {
      this.queryClick()
    } else {
      this.getData()
    }
  },
  destroyed() {
    if (!['AllTransferOrderAdded', 'IframeAllTransferOrderAdded'].includes(this.$route.name)) {
      sessionStorage.removeItem('batchInsertTransfer')
      sessionStorage.removeItem('InsertTransfer')
    }
  },
  methods: {
    async queryClick() {
      const detailParam = this.$route.query.transferOrderCode
      const { datas } = await getmodifyTransferView(detailParam)
      this.form = datas
      this.outboundDetailTable = datas.detailList
      // .map(item => Object.assign(item, { platSku: item.platSku }))
      if (this.form.transferTypeDict === '1') {
        this.status = 1
      } else {
        this.status = 0
      }
      this.outboundDetailTable = datas.detailList.map(item => {
        if (!item.platSku && Array.isArray(item.platSkuDTOList) && item.platSkuDTOList.length > 1 && (this.status === 1 || this.form.transferTypeDict === '1')) {
          item.platSku = item.platSkuDTOList[0].platSku
          item.fnskuUpc = item.platSkuDTOList[0].fnskuUpc
          item.upc = item.platSkuDTOList[0].upc
          item.fnsku = item.platSkuDTOList[0].fnsku
        }

        return item
      })
      sessionStorage.setItem('batchInsertTransfer', JSON.stringify(this.outboundDetailTable))
      sessionStorage.setItem('InsertTransfer', JSON.stringify(this.form))
    },
    // 将当前数据与缓存数据合并
    getData() {
      const queryStorage = sessionStorage.getItem('batchInsertTransfer') ? JSON.parse(sessionStorage.getItem('batchInsertTransfer')).map(item => {
        if (item.OrderNum && !item.expectTransferNumber) {
          return Object.assign(item, { expectTransferNumber: parseInt(item.OrderNum) })
        } else {
          return item
        }
      }) : []
      const queryForm = JSON.parse(sessionStorage.getItem('InsertTransfer'))
      queryForm && (this.form = queryForm)
      if (this.form.transferTypeDict === '1') {
        this.status = 1
      } else {
        this.status = 0
      }
      this.outboundDetailTable = queryStorage.map(item => {
        if (!item.platSku) {
          if (Array.isArray(item.platSkuDTOList) && item.platSkuDTOList.length > 1) {
            if (this.status === 1 || this.form.transferTypeDict === '1') {
              item.platSku = item.platSkuDTOList?.[0]?.platSku
              item.fnskuUpc = item.platSkuDTOList?.[0]?.fnskuUpc
              item.upc = item.platSkuDTOList?.[0]?.upc
              item.fnsku = item.platSkuDTOList?.[0]?.fnsku
            }
            if (item.defaultPlatSku) {
              item.platSku = item.defaultPlatSku
              item.platSkuDTOList.forEach(i => {
                if (i.platSku === item.platSku) {
                  item.fnsku = i.fnsku
                  item.platSku = i.platSku
                  item.upc = i.upc
                  item.fnskuUpc = i.fnskuUpc
                }
              })
            }
          } else if (item.platSkuDTOList.length === 1) {
            item.platSku = item.platSkuDTOList?.[0]?.platSku
            item.fnskuUpc = item.platSkuDTOList?.[0]?.fnskuUpc
            item.upc = item.platSkuDTOList?.[0]?.upc
            item.fnsku = item.platSkuDTOList?.[0]?.fnsku
          }
        }

        return item
      })
      sessionStorage.setItem('batchInsertTransfer', JSON.stringify(this.outboundDetailTable))
    },

    getfuValue({ fnskuUpc, fnsku, upc, platSku }, row) {
      Object.assign(row, { fnskuUpc, upc, fnsku, platSku })
      console.log('{ fnskuUpc, fnsku, upc, platSku }, row: ', { fnskuUpc, fnsku, upc, platSku }, row)
      sessionStorage.setItem('batchInsertTransfer', JSON.stringify(this.outboundDetailTable))
    },
    // 下拉数据获取
    async _queryWarehouseList() {
      const { datas } = await getPhysicsWarehouseList({ status: 1 })
      this.warehouseOption = datas
    },
    async _StockChannelList() {
      const { datas } = await StockChannelList({ status: 1 })
      this.StockChannelOptions = datas
      this.StockChannelOptions1 = this.StockChannelOptions
      this.StockChannelOptions2 = this.StockChannelOptions
    },
    // 获取相关调拨类型
    async getWarehouse() {
      if (this.form.inWarehouseCode && this.form.outWarehouseCode) {
        const inWarehouseAreaType = this.warehouseOption.find(item => item.logicWarehouseCode === this.form.inWarehouseCode).areaType
        const outWarehouseAreaType = this.warehouseOption.find(item => item.logicWarehouseCode === this.form.outWarehouseCode).areaType
        const getParams = Object.assign({}, { inWarehouseAreaType, outWarehouseAreaType }, { inWarehouseCode: this.form.inWarehouseCode, outWarehouseCode: this.form.outWarehouseCode })
        const { code, datas } = await calcTransferType(getParams)
        if (code === 0) {
          this.form.transferTypeDict = datas
          if (!datas) {
            this.$message({
              message: '不支持的调拨类型',
              type: 'warning'
            })
            this.form.transferTypeDict = ''
          }
          if (datas === '1') {
            this.status = 1
            if (this.form.inStockChannelId && this.form.outStockChannelId && this.form.inStockChannelId === this.form.outStockChannelId) {
              this.$message.warning('仓内调拨的调入备货渠道不能等于调出备货渠道')
              this.form.inStockChannelId = ''
            }
            this.getOutStock()
          } else {
            this.status = 0
            this.form.inStockChannelId = this.form.outStockChannelId
            this.StockChannelOptions1 = this.StockChannelOptions
            this.StockChannelOptions2 = this.StockChannelOptions
          }
        }
      }
      this.errorInWare = false
      this.errorOutWare = false
      this.errorInStock = false
      this.errorOutStock = false
    },
    // 调入备货渠道的值
    getOutStock() {
      if (this.status === 1) {
        if (this.form.outStockChannelId && !this.form.inStockChannelId) {
          this.StockChannelOptions2 = this.StockChannelOptions.filter(item => item.channelId !== this.form.outStockChannelId)
        }
        if (this.form.inStockChannelId && !this.form.outStockChannelId) {
          this.StockChannelOptions1 = this.StockChannelOptions.filter(item => item.channelId !== this.form.inStockChannelId)
        }
        if (!this.form.inStockChannelId && !this.form.outStockChannelId) {
          this.StockChannelOptions1 = this.StockChannelOptions
          this.StockChannelOptions2 = this.StockChannelOptions
        }
        if (this.form.inStockChannelId === this.form.outStockChannelId) {
          this.$message.warning('仓内调拨的调入备货渠道不能等于调出备货渠道')
          this.form.inStockChannelId = ''
        }
      } else {
        this.form.inStockChannelId = this.form.outStockChannelId
      }
      this.errorInWare = false
      this.errorOutWare = false
      this.errorInStock = false
      this.errorOutStock = false
    },
    // 一键赋值
    getValue() {
      this.outboundDetailTable.map((item) => {
        Object.assign(item, { expectTransferNumber: item.availableStock })
      })
      sessionStorage.setItem('batchInsertTransfer', JSON.stringify(this.outboundDetailTable))
    },
    // 批量新增
    async Addall() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            this.addLoading = true
            await this._StockChannelList()
            await this._queryWarehouseList()
            const { inStockChannelId, outStockChannelId, outWarehouseCode, inWarehouseCode, transferTypeDict } = this.form
            const inStockChannelName = this.StockChannelOptions.find(item => item.channelId === inStockChannelId).channelName
            const outStockChannelName = this.StockChannelOptions.find(item => item.channelId === outStockChannelId).channelName
            const outWarehouseName = this.warehouseOption.find(item => item.logicWarehouseCode === outWarehouseCode).warehouseName
            const inWarehouseName = this.warehouseOption.find(item => item.logicWarehouseCode === inWarehouseCode).warehouseName
            const addParams = Object.assign({}, { inStockChannelId, inWarehouseCode,
              outStockChannelId, outWarehouseCode, transferTypeDict },
            { inStockChannelName, outStockChannelName, outWarehouseName, inWarehouseName })
            const { code, datas } = await batchInsertPreValidate(addParams)
            this.addLoading = false
            this.form = Object.assign({}, this.form, addParams)
            sessionStorage.setItem('InsertTransfer', JSON.stringify(this.form))
            if (code === 0) {
              const { inStockChannelId, outStockChannelId } = this.form
              if (datas.length > 0) {
                const error = datas.map(item => item.errorMsg).join('<br>')
                this.$message({
                  message: error,
                  dangerouslyUseHTMLString: true,
                  type: 'error'
                })
                this.errorInWare = datas.some(item => item.errorWarehouseCodeList.includes(inWarehouseCode))
                this.errorOutWare = datas.some(item => item.errorWarehouseCodeList.includes(outWarehouseCode))
                this.errorInStock = datas.some(item => item.errorStockChannelIdList == inStockChannelId)
                this.errorOutStock = datas.some(item => item.errorStockChannelIdList == outStockChannelId)
                const arr = []
                if (this.errorInWare) {
                  arr.push('inWarehouseCode')
                }
                if (this.errorOutWare) {
                  arr.push('outWarehouseCode')
                }
                if (this.errorInStock) {
                  arr.push('inStockChannelId')
                }
                if (this.errorOutStock) {
                  arr.push('outStockChannelId')
                }
                this.$refs.form.validateField(arr)
                this.errorInWare = false
                this.errorOutWare = false
                this.errorInStock = false
                this.errorOutStock = false
              } else {
                const styleIdList = this.outboundDetailTable.map(item => item.styleId)
                const colorList = this.outboundDetailTable.map(item => item.color)
                // sessionStorage.setItem('InsertTransfer', JSON.stringify(this.form))
                this.$router.push({
                  name: this.isIframe ? 'IframeAllTransferOrderAdded' : 'AllTransferOrderAdded',
                  query: { inStockChannelId, outWarehouseCode: this.form.outWarehouseCode, outStockChannelId: this.form.outStockChannelId, transferTypeDict: this.form.transferTypeDict,
                    inWarehouseCode: this.form.inWarehouseCode, styleIdList, colorList, DetailTable: this.outboundDetailTable, edit: true, transferOrderCode: this.$route.query.transferOrderCode }
                })
              }
            }
          } catch (err) {
            this.addLoading = false
          }
        } else {
          return false
        }
      })
    },
    // 删除
    handleDelete() {
      if (Array.isArray(this.selectlist) && this.selectlist.length) {
        this.$confirm(`您选中了${this.selectlist.length}笔数据，该操作无法撤销，请确认！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const data = this.outboundDetailTable.filter(v => this.selectlist.every(item => item.sku !== v.sku))

          this.outboundDetailTable = data
          sessionStorage.setItem('batchInsertTransfer', JSON.stringify(data))
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.$message({
          message: '请至少选择一条数据删除',
          type: 'warning'
        })
        // return false
      }
    },
    goBack() {
      this.$router.push({
        name: this.isIframe ? 'IframeTransferOrderManagement' : 'TransferOrderManagement'
      })
    },
    async onSave() {
      const errNum = this.outboundDetailTable.filter(item => item.expectTransferNumber > item.availableStock)
      if (errNum.length > 0) {
        return this.$message.warning('预计调拨数量必须小于可用库存')
      }
      if (!this.isNull) return this.$message.warning('必须有调拨明细')
      const $table = this.$refs.uploadTableDataRef
      const errMap = await $table.validate(true).catch(errMap => errMap)
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          if (!errMap) {
            try {
              this.Loadingsave = true
              const { inStockChannelId, outStockChannelId, outWarehouseCode, inWarehouseCode } = this.form
              const inStockChannelName = this.StockChannelOptions.find(item => item.channelId === inStockChannelId).channelName
              const outStockChannelName = this.StockChannelOptions.find(item => item.channelId === outStockChannelId).channelName
              const outWarehouseName = this.warehouseOption.find(item => item.logicWarehouseCode === outWarehouseCode).warehouseName
              const inWarehouseName = this.warehouseOption.find(item => item.logicWarehouseCode === inWarehouseCode).warehouseName
              const saveParam = Object.assign({}, { inStockChannelName, outStockChannelName, outWarehouseName, inWarehouseName }, this.form, { detailList: this.outboundDetailTable })
              sessionStorage.setItem('batchInsertTransfer', JSON.stringify(this.outboundDetailTable))
              const { code } = await modifyTransferSave(saveParam)
              if (code === 0) {
                this.$message({
                  type: 'success',
                  message: '保存成功!'
                })
                this.goBack()
              }
              this.Loadingsave = false
            } finally {
              this.Loadingsave = false
            }
          } else {
            this.$message.error('校验不通过')
            return false
          }
        } else {
          this.$message.error('校验不通过')
          return false
        }
      })
    },
    basicSuccess() {
      this.$message({
        type: 'success',
        message: '提交成功!'
      })
      this.goBack()
    },
    getChannelName(channelId, options) {
      return options.find(item => item.channelId === channelId)?.channelName
    },

    getWarehouseName(warehouseCode, options) {
      return options.find(item => item.logicWarehouseCode === warehouseCode)?.warehouseName
    },
    showErrorMessages(error, datas, inWarehouseCode, outWarehouseCode, inStockChannelId, outStockChannelId) {
      this.$message({
        message: error,
        dangerouslyUseHTMLString: true,
        type: 'error'
      })
      this.errorInWare = datas.validateList.some(item => item.errorWarehouseCodeList.includes(inWarehouseCode))
      this.errorOutWare = datas.validateList.some(item => item.errorWarehouseCodeList.includes(outWarehouseCode))
      this.errorInStock = datas.validateList.some(item => item.errorStockChannelIdList === inStockChannelId)
      this.errorOutStock = datas.validateList.some(item => item.errorStockChannelIdList === outStockChannelId)
      const arr = []
      if (this.errorInWare) {
        arr.push('inWarehouseCode')
      }
      if (this.errorOutWare) {
        arr.push('outWarehouseCode')
      }
      if (this.errorInStock) {
        arr.push('inStockChannelId')
      }
      if (this.errorOutStock) {
        arr.push('outStockChannelId')
      }
      this.$refs.form.validateField(arr)
      this.errorInWare = false
      this.errorOutWare = false
      this.errorInStock = false
      this.errorOutStock = false
    },
    handleToWarehouse(datas) {
      if (this.form.transferTypeDict === '1') {
        this.basicSuccess()
      } else {
        this.$confirm('调拨单生成成功，是否立即转仓库作业？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async() => {
            // 跳转转仓库作业
            this.$router.push({
              name: this.isIframe ? 'IframeToWarehouse' : 'ToWarehouse',
              query: { 'selectTosure': [datas.transferOrderCode] }
            })
          }).catch(() => {
            this.basicSuccess()
          })
      }
    },
    async handleSubmit() {
      try {
        this.Loadingcommit = true
        this.form = JSON.parse(sessionStorage.getItem('InsertTransfer'))
        const { inStockChannelId, outStockChannelId, outWarehouseCode, inWarehouseCode } = this.form
        const inStockChannelName = this.getChannelName(inStockChannelId, this.StockChannelOptions)
        const outStockChannelName = this.getChannelName(outStockChannelId, this.StockChannelOptions)
        const outWarehouseName = this.getWarehouseName(outWarehouseCode, this.warehouseOption)
        const inWarehouseName = this.getWarehouseName(inWarehouseCode, this.warehouseOption)
        const confirmPartialTransferFlag = false
        const saveParam = Object.assign({}, { inStockChannelName, outStockChannelName, outWarehouseName, inWarehouseName, confirmPartialTransferFlag }, this.form, { detailList: this.outboundDetailTable })
        sessionStorage.setItem('batchInsertTransfer', JSON.stringify(this.outboundDetailTable))
        const { code, datas } = await modifyTransferSubmit(saveParam)
        if (code === 0) {
          if (Array.isArray(datas?.validateList) && datas?.validateList.length) {
            const error = datas.validateList.map(item => item.errorMsg).join('<br>')
            this.showErrorMessages(error, datas, inWarehouseCode, outWarehouseCode, inStockChannelId, outStockChannelId)
          } else if (datas?.tip) {
            this.$confirm(`${datas.tip}`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(async() => {
                const confirmPartialTransferFlag = true
                const { datas } = await modifyTransferSubmit(Object.assign({}, saveParam, { confirmPartialTransferFlag }))
                if (datas?.transferOrderCode) {
                  this.handleToWarehouse(datas)
                }
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消'
                })
              })
          } else if (datas?.transferOrderCode) {
            this.handleToWarehouse(datas)
          }
        }

        this.Loadingcommit = false
      } finally {
        this.Loadingcommit = false
      }
    },
    async onSubmit() {
      const errNum = this.outboundDetailTable.filter(item => item.expectTransferNumber > item.availableStock)
      if (errNum.length > 0) {
        return this.$message.warning('预计调拨数量必须小于可用库存')
      }
      if (!this.isNull) return this.$message.warning('必须有调拨明细')
      const { transferTypeDict } = this.form
      if (this.outboundDetailTable.some(v => v.negativeCashFlag === '是') && transferTypeDict !== '1') return this.$message.warning('存在负现金流产品不允许补货')

      const $table = this.$refs.uploadTableDataRef
      const errMap = await $table.validate(true).catch(errMap => errMap)
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          if (!errMap) {
            if (this.outboundDetailTable.some(v => v.position === 'EOL') && transferTypeDict !== '1') {
              this.$confirm('存在EOL产品是否补货', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.handleSubmit()
              })
            } else {
              this.handleSubmit()
            }
          } else {
            this.$message.error('校验不通过')
            return false
          }
        } else {
          this.$message.error('校验不通过')
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scope>
.warning-row {
  color: red !important;
}
</style>

